import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import CssBaseline from "@material-ui/core/CssBaseline"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"

import Paper from "@material-ui/core/Paper"

const styles = theme => ({
  text: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing.unit * 2,
  },
  subHeader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    alignItems: "center",
    ...theme.mixins.toolbar,
    marginTop: "40px",
    boxShadow: "0px 0px 6px 1px rgba(1, 1, 1, 0.6);",
  },
  toolbar: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    alignItems: "center",
    justifyContent: "space-between",
  },
  Typography: {
    justifyContent: "center",
    textAlign: "center",
  },
})

function BottomAppBar(props) {
  const { classes } = props
  const currentYear = new Date().getFullYear()
  return (
    <div>
      <AppBar
        position="static"
        color="primary"
        style={{
          top: "auto",
          bottom: 0,
          alignItems: "center",
          marginTop: "40px",
          boxShadow: "0px 0px 6px 1px rgba(1, 1, 1, 0.6);",
          width: "100%",
        }}
      >
        <Toolbar
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Typography
            centered
            className={classes.Typography}
            variant="subheading"
            component={"span"}
            style={{
              textAlign: "center",
              justifyContent: "center",
              color: "white",
            }}
          >
            Copyright © 2005 - {currentYear} American Dream Construction, LLC.
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  )
}

BottomAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(BottomAppBar)
