import React, { Component } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import classNames from "classnames"

import Responsive from "react-responsive"
//mui
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import MenuList from "@material-ui/core/MenuList"
import MenuItem from "@material-ui/core/MenuItem"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { withStyles } from "@material-ui/core/styles"
import theme from "../theme/theme.js"
const drawerWidth = 240

const styles = theme => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 12,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
})

export class DrawerBar extends Component {
  state = {
    open: false,
  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
  }

  handleDrawerClose = () => {
    this.setState({ open: false })
  }

  handleClick = () => {
    this.setState(state => ({ open: !state.open }))
  }
  render() {
    const { classes } = this.props
    const { open } = this.state

    return (
      <div>
        {" "}
        <nav style={{ marginBottom: `3.5rem`, boxShadow: "5px 10px;" }}>
          <AppBar
            position="fixed"
            className={classNames(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar disableGutters={!open}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
              <div style={{ textAlign: "center", margin: "0 auto" }}>
                <Link
                  to="/"
                  exact
                  style={{
                    textDecoration: "none",
                    color: "white",
                  }}
                >
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                    align="justify-center"
                    style={{
                      marginLeft: "-30px",
                      textDecoration: "none",
                      color: "white",
                      alignItems: "center",
                      fontSize: "14px",
                      left: "50%",
                    }}
                    className="app-header"
                  >
                    American Dream Construction, LLC
                  </Typography>
                </Link>
              </div>

              <iconElementRight />
            </Toolbar>
          </AppBar>

          <Drawer
            className={classes.drawer}
            variant="temporary"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={this.handleDrawerClose}>
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <MenuList>
              <MenuItem
                component={Link}
                to="/careers"
                style={{ justifyContent: "center" }}
              >
                {" "}
                Careers
              </MenuItem>

              <Divider />

              <MenuItem
                component={Link}
                to="/"
                style={{ justifyContent: "center" }}
              >
                {" "}
                Home
              </MenuItem>

              <Divider />

              <MenuItem
                component={Link}
                to="/about"
                style={{ justifyContent: "center" }}
              >
                About
              </MenuItem>
              <Divider />

              <MenuItem
                component={Link}
                to="/services"
                style={{ justifyContent: "center" }}
              >
                Services
              </MenuItem>
              <Divider />

              <MenuItem
                component={Link}
                to="/contact"
                style={{ justifyContent: "center" }}
              >
                Contact
              </MenuItem>
            </MenuList>
            <Divider />
          </Drawer>
        </nav>
      </div>
    )
  }
}
Drawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(DrawerBar)
