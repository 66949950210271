import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { component } from "react"
//material ui components
import CssBaseline from "@material-ui/core/CssBaseline"
import Responsive from "react-responsive"
import { withStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"

import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

const Mobile = props => <Responsive {...props} maxWidth={767} />
const Default = props => <Responsive {...props} minWidth={768} />

const HeaderLinks = {
  textDecoration: "none",
  color: "inherit",
}

const HeaderLinksActive = {
  // backgroundColor: "rgb(255,255,255,.1)",
  borderBottom: "white 2px solid",
}

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
}
function Header(props, { siteTitle }) {
  const { classes } = props

  return (
    <div className={classes.root} style={{ marginBottom: "3.5rem" }}>
      <AppBar>
        <Toolbar>
          <Typography
            variant="h6"
            color="inherit"
            className={classes.grow}
            style={{ textAlign: "left" }}
          >
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <Default>American Dream Construction, LLC</Default>
              <Mobile> ADC </Mobile>
            </Link>
          </Typography>

          <Button color="inherit" component={Link} to="/careers">
            {" "}
            Careers{" "}
          </Button>

          <Button color="inherit" component={Link} to="/">
            {" "}
            Home{" "}
          </Button>

          <Button color="inherit" component={Link} to="/about">
            {" "}
            About{" "}
          </Button>
          <Button color="inherit" component={Link} to="/services">
            {" "}
            Services{" "}
          </Button>
          <Button color="inherit" component={Link} to="/contact">
            Contact{" "}
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default withStyles(styles, { withTheme: true })(Header)
