/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles"
import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

//components
import Header from "./header"
import DrawerBar from "./DrawerBar"

//material ui components
import CssBaseline from "@material-ui/core/CssBaseline"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import theme from "../theme/theme.js"
import { withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import BottomAppBar from "./BottomAppBar"

import Responsive from "react-responsive"

//stylings
import "../css/styles.css"
import "./layout.css"
import "typeface-roboto"

//media queries
const Desktop = props => <Responsive {...props} minWidth={992} />
const Tablet = props => <Responsive {...props} minWidth={768} maxWidth={991} />
const Mobile = props => <Responsive {...props} maxWidth={767} />
const Default = props => <Responsive {...props} minWidth={768} />

const headerStyle = {}

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <CssBaseline>
          <MuiThemeProvider theme={theme}>
            <Default>
              <Header siteTitle={data.site.siteMetadata.title} />
            </Default>
            <Mobile>
              <DrawerBar />
            </Mobile>
            <div
              style={{
                margin: `0 auto`,
                //maxWidth: 960,
                padding: `0px 0px 0px`,
                paddingTop: 0,
              }}
            >
              <body>
                <div style={{ top: 0 }}>
                  <main>{children}</main>
                </div>
              </body>
              {/* Footer section */}
              <BottomAppBar />
            </div>
          </MuiThemeProvider>
        </CssBaseline>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles({ withTheme: true })(Layout)
